<template>
  <div class="bg-white shadow px-2 pt-2 pb-2 rounded" v-if="currentCompany">
    <div class="flex flex-row justify-between">
      <div class="flex items-center space-x-2">
        <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
          Company Details
        </p>
        <SubscriptionStatusBox :subStatus="subscriptionStatus" :onAdmin="null" hideTitle />
      </div>
      <div>
        <div>
          <Button
            text="Edit"
            type="secondary"
            size="medium"
            :iconLeft="edit"
            @click.native="editTeam"
            data-testid="edit-company-button"
          />
        </div>
      </div>
    </div>
    <div class="flex w-5/6 flex-row items-start justify-between ml-2">
      <div class="flex flex-col justify-between">
        <p class="text-sm-2 text-grey-light">Company Name</p>
        <p class="font-medium">{{ currentCompany.name }}</p>
      </div>
      <div class="flex flex-col justify-between">
        <p class="text-sm-2 text-grey-light flex flex-row">
          Industry
        </p>
        <p v-if="currentCompany.industryName" class="font-medium">
          {{ currentCompany.industryName }}
        </p>
        <p v-else class="font-medium">No industry.</p>
      </div>
      <div class="flex flex-col justify-between" v-if="invitesInfo">
        <p class="text-sm-2 text-grey-light">Invites Remaining</p>
        <p class="font-medium">{{ allowUnlimitedInvites ? 'Unlimited' : invitesInfo.totalRemaining }}</p>
      </div>
      <div class="flex flex-col justify-between">
        <p class="text-sm-2 text-grey-light">Active Users</p>
        <p class="font-medium">{{ currentCompany.activeUsersCount }}</p>
      </div>
      <div class="flex flex-col justify-between">
        <p class="text-sm-2 text-grey-light flex flex-row">
          <img
            :src="warning"
            width="20"
            height="20"
            class="warning-icon"
            v-if="currentCompany.adminsUsersCount < 1"
          />
          Admins
        </p>
        <p class="font-medium">{{ currentCompany.adminsUsersCount }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import edit from '@/assets/img/icons/edit.svg';
import warning from '@/assets/img/icons/warning.svg';
import SubscriptionStatusBox from '../../../components/SubscriptionStatusBox/SubscriptionStatusBox.vue'
import { mapState } from 'vuex';

export default {
  name: 'CompanyDetailsCard',
  data: () => ({ edit, warning }),
  components: {
    SubscriptionStatusBox
  },
  props: {
    invitesInfo: {
      type: Object,
      default: () => ({})
    },
    subscriptionStatus: {
      type: Number,
      default: 1,
    }
  },
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
      allowUnlimitedInvites: state => state.invites.allowUnlimitedInvites
    }),
  },
  methods: {
    editTeam() {
      this.$router.push(
        `/company/${this.currentCompany.id}/overview/company-details`
      );
    },
  },
};
</script>

<style scoped lang="scss">
.warning-icon {
  margin-left: -20px;
  padding-right: 5px;
}
</style>
