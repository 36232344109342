<template>
  <div class="bg-white shadow px-2 pt-2 pb-2 rounded">
    <p class="font-barlow text-mdl font-bold text-grey-light mb-2">Admins</p>
    <div class="px-2 pt-1">
      <div class="flex flex-row items-end justify-between">
        <div>
          <InputField
            :placeholder="inputPlaceholder"
            :label="inputLabel"
            :onChange="setKeyword"
            leftIcon
            class="mb-2 w-inputField"
            data-testid="filter"
          />
        </div>
        <div>
          <div class="mb-2">
            <Button
              text="New Admin"
              type="primary"
              :iconLeft="add"
              size="medium"
              class="add-admin-btn"
              @click.native="inviteAdmin"
              data-testid="invite-admin-button"
            />
          </div>
        </div>
      </div>
      <p class="text-sm-2 text-grey-dark-2 mb-2">
        Showing {{ mappedList.length }} {{mappedList.length === 1 ? 'Admin' : 'Admins'}} out of
        {{ currentCompany.adminsUsersCount }}
      </p>
      <div>
        <div
          class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded"
          :class="mappedList.length < 10 ? '' : 'pr-2'"
        >
          <div class="flex flex-grow max-w-1/2">
            <button
              class="flex flex-row max-w-1/5 items-center focus:outline-none mr-8"
              data-testid="header-1"
            >
              <p class="text-sm-2 text-grey-light mr-1">NO.</p>
            </button>
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="() => sortCol('NAME')"
              data-testid="header-2"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <div
            class="flex flex-grow max-w-1/3 justify-start"
            data-testid="header-3"
          >
            <button
              class="flex flex-row items-center focus:outline-none"
              @click="sortCol('EMAIL')"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
              <img
                src="../../../../assets/img/delta-table/caret.svg"
                class="transition-all"
                :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
              />
            </button>
          </div>
          <button
            class="flex flex-row items-center focus:outline-none ml-4"
            data-testid="header-4"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
          </button>
        </div>
        <div>
          <VirtualList
            style="max-height: 360px; overflow-y: auto"
            :data-key="'email'"
            :data-sources="mappedList"
            :data-component="itemComponent"
            :extra-props="{ manage: editAdmin }"
            @tobottom="triggerFetch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import add from "@/assets/img/icons/add.svg";

export default {
  name: "AdminsTable",
  components: { VirtualList },
  props: {
    inputLabel: { type: String, default: "Filter by" },
    inputPlaceholder: { type: String, default: "Search by name or email" },
    usersCount: { type: Number },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    add,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    pageNr: 1,
    sorting: null,
  }),
  async mounted() {
    await this.updateList();
  },
  watch: {
    keyword() {
      this.pageNr = 1;
      this.mappedList = [];
      this.updateList();
    },
    fetchAdmins(val) {
      if (val) {
        this.updateList(true);
      }
    },
  },
  computed: {
    ...mapState({
      admins: (state) => state.companies.admins,
      adminsCount: (state) => state.companies.adminsCount,
      currentCompany: (state) => state.companies.currentCompany,
      fetchAdmins: (state) => state.companies.fetchAdmins,
    }),
  },
  methods: {
    ...mapActions({
      fetchCompanyAdmins: "companies/fetchCompanyAdmins",
      openModal: "modals/openModal",
      setSelectedUser: "companies/setSelectedUser",
    }),
    editAdmin(admin) {
      this.setSelectedUser({ user: admin });
      this.$router.push(`/company/${this.currentCompany.id}/admin/${admin.id}`);
    },
    inviteAdmin() {
      this.openModal({
        modal: "editAdminModal",
      });
    },
    sortCol(col) {
            switch (col) {
        case "NAME":
          this.rotationMatrix[0] *= -1;
          this.sorting = { firstName: this.rotationMatrix[0] === -1 ? 1 : 2 }
          break;
        case "EMAIL":
          this.rotationMatrix[1] *= -1;
          this.sorting = { email: this.rotationMatrix[1] === -1 ? 1 : 2 }
          break;
        default:
          break;
      }
      this.updateList(true)
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setKeyword: debounce(function (value) {
      this.keyword = value;
    }, 500),
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.currentCompany.adminsUsersCount / 10)) {
        this.pageNr++;
        this.updateList();
      }
    },
    async updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      const payload = {
        keyword: this.keyword,
        pageNumber: this.pageNr,
        pageSize: 20,
        sorting: this.sorting,
        role: "CompanyAdmin",
        allowInviteInactiveUsers: true
      };
      this.fetchCompanyAdmins({
        id: this.currentCompany.id,
        payload,
      }).then((rsp) => {
        let map = rsp.map((item, idx) => ({
          ...item,
          isSelected: false,
          index: (this.pageNr - 1) * 20 + idx,
        })).filter(item => item.firstName !== "Anonymised");
        this.mappedList = [...this.mappedList, ...map];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.add-admin-btn {
  line-height: 1;
}
</style>
