<template>
  <div :data-testid="`adminstable-listitem-${index}`">
    <div class="flex flex-row w-full py-1 pl-2 items-center">
      <div class="flex flex-grow max-w-1/2">
        <div class="flex justify-center mr-8 pr-3">
          <p class="font-normal text-md text-grey-dark-2">
            {{ index + 1 }}
          </p>
        </div>
        <div class="flex flex-grow max-w-1/3">
          <p class="text-md text-grey-dark-1 font-semibold">
            {{ source.fullName }}
          </p>
        </div>
      </div>
      <div class="flex flex-grow max-w-1/3">
        <p class="text-md">
          {{ source.email }}
        </p>
      </div>
      <div class="flex flex-row items-center focus:outline-none ml-2">
        <Button
          text="Manage"
          type="secondary"
          size="medium"
          @click.native="manage(source)"
          data-testid="manage-button"
        />
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
export default {
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    manage: { type: Function, default: () => null },
  },
};
</script>
