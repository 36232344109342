<template>
  <div>
    <div class="flex flex-row w-full">
      <div class="w-1/2">
        <h1
          class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"
        >
          <span class="text-grey-medium-2 font-normal"> Companies / </span>
          {{ currentCompany.name }}
        </h1>
      </div>
      <div class="flex w-1/2 justify-end">
        <Button
          text="Open as Company Admin"
          type="primary-white"
          size="medium"
          @click.native="doImpersonate"
          data-testid="impersonate-button"
        />
      </div>
    </div>
    <div class="pb-3">
      <CompanyDetailsCard :invitesInfo="invitesInfo" :subscriptionStatus="subscriptionStatus"/>
    </div>
    <div class="pb-3">
      <div v-if="currentCompany.adminsUsersCount < 1">
        <EmptyState
          :callback="inviteAdmin"
          title="Admins"
          description="No admins added yet."
          btnText="Invite Admin"
        />
      </div>
      <div v-else>
        <div v-if="isOkToLoadTable">
          <AdminsTable />
        </div>
      </div>
    </div>
    <div class="pb-3">
      <Licenses :invitesInfo="invitesInfo"/>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CompanyDetailsCard from '../components/CompanyDetailsCard';
import EmptyState from '../components/AdminsTable/EmptyState';
import AdminsTable from '../components/AdminsTable/index';
import Licenses from '../components/Licenses/index';

export default {
  name: 'CompanyDetailsView',
  components: { CompanyDetailsCard, EmptyState, AdminsTable, Licenses },
  data: () => ({ isOkToLoadTable: false }),
  async mounted() {
    await Promise.all([
      this.getCompany(),
      this.getAllIndustries(),
      this.getOrgInvitesInfo(this.$route.params.companyId),
      this.getSubscriptionDetails(this.$route.params.companyId),
    ])
    this.isOkToLoadTable = true;
  },
  watch: {
    currentCompany(val) {
      return val;
    },
  },
  methods: {
    ...mapActions({
      openModal: 'modals/openModal',
      getCompanyDetails: 'companies/getCompanyDetails',
      getIndustries: 'companies/getIndustries',
      getOrgInvitesInfo: 'invites/getOrgInvitesInfo',
      impersonateAdmin: 'auth/impersonateAdmin',
      getSubscriptionDetails: "subscriptions/getSubscriptionDetails",
    }),
    getCompany() {
      return this.getCompanyDetails({ id: this.$route.params.companyId });
    },
    doImpersonate() {
      this.impersonateAdmin({ orgId: this.currentCompany.id });
    },
    getAllIndustries() {
      return this.getIndustries();
    },
    inviteAdmin() {
      this.openModal({
        modal: 'editAdminModal',
      });
    },
  },
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
      adminsCount: (state) => state.companies.adminsCount,
      invitesInfo: (state) => state.invites.invitesInfo,
      subscriptionStatus: (state) => state.subscriptions?.subscriptionDetails?.subscriptionStatus,
    }),
  },
};
</script>

<style></style>
